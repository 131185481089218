import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icons/Icon';
import Button from '../Button/Button';

const PricingCard = ({ variant, plan, description, currency, price, cadency, features, callback }) => {
  const cardClasses = {
    primary: 'tw-bg-gradient-to-b tw-from-[#ff5828] tw-to-[#993518]',
    secondary: 'tw-bg-gradient-to-b tw-from-[#e5e5e5] tw-to-[#cdcdcd]',
  };
  const textHeaderClasses = {
    primary: 'tw-text-gray-50',
    secondary: 'tw-text-gray-600',
  };
  const textFeaturesClasses = {
    primary: 'tw-text-gray-50',
    secondary: 'tw-text-gray-900',
  };
  const buttonClasses = {
    primary: 'alternative',
    secondary: 'primary',
  };

  return (
    <div className={`${cardClasses[variant]} tw-w-full tw-max-w-md tw-rounded-3xl tw-gap-20 tw-py-14 tw-px-10`}>
      <div className="tw-flex tw-flex-col tw-justify-center tw-gap-4 tw-mb-4">
        <h5 className={`${textHeaderClasses[variant]} tw-mx-auto tw-mb-4 tw-text-4xl tw-font-extrabold`}>{plan}</h5>
        <div className={`${textHeaderClasses[variant]} tw-mx-auto tw-text-lg tw-font-normal`}>{description}</div>
      </div>
      <div className={`${textHeaderClasses[variant]} tw-flex tw-items-baseline tw-justify-center tw-gap-2.5`}>
        <span className="tw-text-5xl tw-font-extrabold tw-tracking-tight">{currency}{price}</span>
        <span className="tw-text-lg tw-font-medium">/{cadency}</span>
      </div>
      <ul className={`${textFeaturesClasses[variant]} tw-space-y-5 tw-my-7`}>
        {features.map((feature, index) => (
          <li key={index} className="tw-flex tw-items-start">
            <Icon name="check" width="14" height="11" className="tw-mt-1" />
            <span className="tw-text-start tw-font-normal tw-leading-tight tw-ms-3">{feature}</span>
          </li>
        ))}
      </ul>
      <Button color={buttonClasses[variant]} outline={variant === 'primary'} buttonText="Get started" width="100%" onClick={callback} />
    </div>
  );
};

PricingCard.propTypes = {
  variant: PropTypes.oneOf(['primary', 'secondary']).isRequired,
  plan: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  cadency: PropTypes.string.isRequired,
  features: PropTypes.arrayOf(PropTypes.string).isRequired,
  callback: PropTypes.func,
};

PricingCard.defaultProps = {
  callback: () => {},
};

export default PricingCard;
