import LogoFull from '../Brand/LogoFull';
import { LinkedinSolid, X, Youtube } from '../Icons/Social';

function Footer({logoColor,motto,bottomNote,menus}) {
  return (
    <>
    <footer className="tw-bg-zinc-800">
      <div className="tw-p-4 tw-py-6 tw-mx-auto tw-max-w-screen-xl md:tw-p-8 lg:tw-py-10">
        <div className="tw-grid tw-grid-cols-2 tw-gap-8 lg:tw-grid-cols-6">
          <div className="tw-col-span-2">

            <a
              href="#"
              className="tw-flex tw-items-center tw-mb-2 tw-text-2xl tw-font-semibold sm:tw-mb-0 tw-text-white"
            >
              <LogoFull fillColor={logoColor}/>
            </a>
            <p className="tw-my-4 tw-font-light tw-text-gray-400">
              {motto}
            </p>
            <ul className="tw-flex tw-mt-5 tw-space-x-4">
              <li>
                <a
                  href="#"
                  className="hover:tw-text-white tw-text-gray-400"
                >
                  <LinkedinSolid/>
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="hover:tw-text-white tw-text-gray-400"
                >
                  <X viewBox="0 0 20 20"/>
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="hover:tw-text-white tw-text-gray-400"
                >
                  <Youtube/>
                </a>
              </li>
            </ul>
          </div>
          {menus.map((menu) => (
            <div className="lg:tw-mx-auto">
              <h2 className="tw-mb-6 tw-text-sm tw-font-semibold tw-uppercase tw-text-white">
                {menu?.header}
              </h2>
              <ul className="tw-text-gray-400">
                {menu?.links.map((link) => (
                  <li className="tw-mb-4">
                    {link?.href
                      ?
                        <a href={link?.href} className="hover:tw-underline">
                          {link?.label}
                        </a>
                      :
                      <span onClick={link?.callback} className="hover:tw-underline">
                          {link?.label}
                      </span>
                    }
                  </li>
                ))}
              </ul>
            </div>
          ))}
          <div className="tw-col-span-2 tw-justify-center tw-items-center tw-inline-flex tw-gap-4">
            <a href="https://www.capterra.com/reviews/343567/Calendbook?utm_source=vendor&utm_medium=badge&utm_campaign=capterra_reviews_badge"> <img width="130" border="0" src="https://assets.capterra.com/badge/c61e3f861e4ae35d055bb5967e386481.svg?v=2290534&p=343567" /> </a>
            <a href="https://www.capterra.com/p/10011017/Calendbook/"> <img width="50" border="0" src="https://capterra.s3.amazonaws.com/assets/images/gdm-badges/ca-ease_of_use-2023.png" /> </a>
            <a href="https://www.getapp.com/operations-management-software/scheduling/category-leaders/"> <img width="60" border="0" src="https://capterra.s3.amazonaws.com/assets/images/gdm-badges/ga-category_leaders-2024.png" /> </a>
            <a href="https://www.softwareadvice.com/scheduling/#frontrunners"> <img width="45" border="0" src="https://capterra.s3.amazonaws.com/assets/images/gdm-badges/sa-frontrunners-2024.png" /> </a>
          </div>
        </div>
        <hr className="tw-my-6 sm:tw-mx-auto tw-border-gray-600 lg:tw-my-8" />
        <span className="tw-block tw-text-sm tw-text-center tw-text-gray-400">
          {bottomNote}
        </span>
      </div>
    </footer>
    </>
  );
}

export default Footer;
