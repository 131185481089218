import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icons/Icon';

const Button = ({
  color,
  size,
  state,
  outline,
  iconOnly,
  showButtonText,
  buttonText,
  showLeftIcon,
  leftIconStyle,
  showRightIcon,
  rightIconStyle,
  iconStyle,
  width,
  height,
  radius,
  padding,
  gap,
  onClick,
}) => {

  const colorClasses = {
    primary: 'tw-bg-primary-500 tw-text-white hover:tw-bg-primary-600 focus:tw-ring focus:tw-ring-primary-200',
    primaryOutline: 'tw-bg-white tw-text-primary-500 tw-border tw-border-primary-500 hover:tw-text-white hover:tw-bg-primary-600 focus:tw-text-white focus:tw-bg-primary-600 focus:tw-ring focus:tw-ring-primary-200',
    dark: 'tw-bg-gray-800 tw-text-white hover:tw-bg-gray-700 focus:tw-ring focus:tw-ring-gray-200',
    darkOutline: 'tw-bg-white tw-text-gray-800 tw-border tw-border-gray-800 hover:tw-text-white hover:tw-bg-gray-700 focus:tw-text-white focus:tw-bg-gray-700 focus:tw-ring focus:tw-ring-gray-200',
    green: 'tw-text-white tw-bg-green-700 hover:tw-bg-green-800 focus:tw-ring focus:tw-ring-green-200',
    greenOutline: 'tw-bg-white tw-text-green-700 tw-border tw-border-green-700 hover:tw-text-white hover:tw-bg-green-800 focus:tw-text-white focus:tw-bg-green-800 focus:tw-ring focus:tw-ring-green-200',
    red: 'tw-bg-red-700 hover:tw-bg-red-800 focus:tw-ring focus:tw-ring-red-200 tw-text-white',
    redOutline: 'tw-bg-white tw-text-red-700 tw-border tw-border-red-700 hover:tw-text-white hover:tw-bg-red-800 focus:tw-text-white focus:tw-bg-red-800 focus:tw-ring focus:tw-ring-red-200',
    alternative: 'tw-bg-gray-200 tw-text-gray-900 hover:tw-bg-gray-100 hover:tw-text-primary-700 focus:tw-text-primary-700 focus:tw-bg-gray-100 focus:tw-ring focus:tw-ring-gray-100',
    alternativeOutline: 'tw-bg-white tw-border tw-border-gray-200 hover:tw-bg-gray-100 hover:tw-text-primary-700 hover:tw-border-gray-200 focus:tw-text-primary-700 focus:tw-bg-gray-100 focus:tw-ring focus:tw-ring-gray-100',
    alternativeDark: 'tw-bg-gray-800 tw-text-gray-400 tw-border tw-border-gray-600 hover:tw-bg-gray-700 hover:tw-text-white hover:tw-border-gray-600 focus:tw-text-white focus:tw-bg-gray-700 focus:tw-ring focus:tw-ring-gray-700',
    alternativeDarkOutline: 'tw-bg-white tw-text-gray-800 tw-border tw-border-gray-600 hover:tw-bg-gray-700 hover:tw-text-white hover:tw-border-gray-600 focus:tw-bg-gray-700 focus:tw-text-white focus:tw-ring focus:tw-ring-gray-700',
    // Add more color mappings as needed
  };

  const sizes = {
    xs: {
      width: "124px",
      height: "34px"
    },
    sm: {
      width: "133px",
      height: "37px"
    },
    base: {
      width: "153px",
      height: "41px"
    },
    l: {
      width: "167px",
      height: "48px"
    },
    xl: {
      width: "175px",
      height: "52px"
    },
    iconOnly: {
      xs: {
        width: "20px",
        height: "20px"
      },
      sm: {
        width: "28px",
        height: "28px"
      },
      base: {
        width: "34px",
        height: "34px"
      },
      l: {
        width: "40px",
        height: "40px"
      },
      xl: {
        width: "44px",
        height: "44px"
      },
    }
  }

  // TODO: replace with theme spacing from tailwind
  const paddings = {
    xs: "8px 16px",
    sm: "8px 16px",
    base: "12px 20px",
    l: "12px 20px",
    xl: "12px 24px",
    iconOnly: {
      xs: "1px",
      sm: "2px",
      base: "2.5px",
      l: "3px",
      xl: "3.5px",
    }
  }

  const iconSizes = {
    xs: "12",
    sm:"12",
    base:"14",
    l:"16",
    xl:"16",
  }

  const getButtonWidth = (_size,_iconOnly) => {
    if(_iconOnly){return sizes.iconOnly[_size]?.width}
    else{return sizes[_size]?.width}
  }

  const getButtonHeight = (_size,_iconOnly) => {
    if(_iconOnly){return sizes.iconOnly[_size]?.height}
    else{return sizes[_size]?.height}
  }

  const getButtonPadding = (_size,_iconOnly) => {
    if(_iconOnly){return paddings.iconOnly[_size]}
    else{return paddings[_size]}
  }

  const colorClass = outline ? colorClasses[`${color}Outline`] : colorClasses[color] || 'tw-bg-primary-500 hover:tw-bg-primary-600 focus:tw-ring-primary-200 tw-text-white';
  const sizeClass = size === 'xs' ? 'tw-text-xs' : size === 'sm' ? 'tw-text-sm' : size === 'base' ? 'tw-text-base' : size === 'l' ? 'tw-text-lg' : 'tw-text-xl';
  const stateClass = state === 'default' ? '' : state;
  const outlineClass = outline ? 'tw-border' : '';
  const radiusClass = radius === 'rounded-full' ? 'tw-rounded-full' : 'tw-rounded';

  const buttonClass = `
    ${colorClass}
    ${sizeClass}
    ${stateClass}
    ${outlineClass}
    ${radiusClass}
    tw-flex tw-items-center tw-justify-center
  `;

  return (
    <button
      onClick={onClick}
      className={buttonClass}
      style={{
        width: width,
        minWidth: getButtonWidth(size,iconOnly),
        height: getButtonHeight(size,iconOnly),
        padding: getButtonPadding(size,iconOnly),
        gap
      }}>
      {!iconOnly && showLeftIcon && <span className={`tw-mr-2`}><Icon name={leftIconStyle} width={iconSizes[size]} height={iconSizes[size]}/></span>}
      {!iconOnly && showButtonText && buttonText}{iconOnly && <span className="tw-flex tw-justify-center tw-items-center"><Icon name={iconStyle} width={iconSizes[size]} height={iconSizes[size]}/></span>}
      {!iconOnly && showRightIcon && <span className={`tw-ml-2`}><Icon name={rightIconStyle} width={iconSizes[size]} height={iconSizes[size]}/></span>}
    </button>
  );
};

Button.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
  state: PropTypes.string,
  outline: PropTypes.bool,
  iconOnly: PropTypes.bool,
  showButtonText: PropTypes.bool,
  buttonText: PropTypes.string,
  showLeftIcon: PropTypes.bool,
  leftIconStyle: PropTypes.string,
  showRightIcon: PropTypes.bool,
  rightIconStyle: PropTypes.string,
  iconStyle: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  radius: PropTypes.string,
  padding: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  gap: PropTypes.string,
};

Button.defaultProps = {
  color: 'primary',
  size: 'base',
  state: 'default',
  outline: false,
  iconOnly: false,
  showButtonText: true,
  buttonText: 'Button text',
  showLeftIcon: false,
  leftIconStyle: 'cart-plus',
  showRightIcon: false,
  rightIconStyle: 'cart-plus',
  iconStyle: 'plus',
  width: 'auto',
  height: 'auto',
  radius: 'rounded-full',
  padding: ['2px', '3px'],
  gap: '2px',
};

export default Button;
