import React from 'react';
import PropTypes from 'prop-types';
import Logo from './Logo';
import Logotype from './Logotype';

export default function LogoFull({ fillColor, gap, width }) {
  return (
    <div className="tw-inline-flex" style={{ gap, width }}>
      <Logo fillColor={fillColor} />
      <Logotype fillColor={fillColor} />
    </div>
  );
}

LogoFull.propTypes = {
  fillColor: PropTypes.string,
  gap: PropTypes.string,
  width: PropTypes.string,
};

LogoFull.defaultProps = {
  fillColor: '#F04C23',
  gap: '8px',
  width: '200px',
};

