import Icon from './Icon';

export const Youtube = (props) => (
  <Icon
    svgContent={<path d="M21.796 8.01679C21.7015 7.30291 21.4274 6.62429 20.9991 6.04377C20.4683 5.51199 19.7486 5.20923 18.9953 5.20092C16.2006 5 12 5 12 5C12 5 7.80143 5 5.00273 5.20092C4.24968 5.20906 3.53015 5.51186 2.9999 6.04377C2.56975 6.62373 2.29516 7.30308 2.202 8.0178C2.07717 9.08704 2.00974 10.1621 2 11.2385V12.7454C2.00968 13.8218 2.07711 14.8969 2.202 15.9661C2.29694 16.6815 2.56903 17.3622 2.99384 17.9472C3.60357 18.4855 4.38752 18.7878 5.20271 18.7991C6.80154 18.9508 12.001 19 12.001 19C12.001 19 16.2036 19 18.9983 18.7991C19.752 18.7903 20.472 18.4873 21.0031 17.9552C21.4304 17.374 21.7038 16.6951 21.798 15.9812C21.9228 14.912 21.9903 13.8369 22 12.7605V11.2375C21.9886 10.1611 21.9205 9.08608 21.796 8.01679ZM9.98707 14.6009V8.95508L15.4411 11.787L9.98707 14.6009Z" /> }
    {...props}
  />
)

export const LinkedinSolid = (props) => (
  <Icon
    svgContent={ <path d="M4 7C4 5.343 5.343 4 7 4H17C18.657 4 20 5.343 20 7V17C20 18.657 18.657 20 17 20H7C5.343 20 4 18.657 4 17V7ZM8.85 17.393V10.169H6.434V17.393H8.85ZM7.642 9.182C8.484 9.182 9.009 8.628 9.009 7.934C8.993 7.225 8.484 6.686 7.658 6.686C6.831 6.686 6.291 7.225 6.291 7.934C6.291 8.628 6.815 9.182 7.626 9.182H7.642ZM12.604 17.393V13.359C12.604 13.143 12.62 12.928 12.683 12.773C12.858 12.342 13.256 11.895 13.923 11.895C14.798 11.895 15.148 12.557 15.148 13.529V17.393H17.564V13.251C17.564 11.032 16.372 9.999 14.782 9.999C13.5 9.999 12.925 10.699 12.604 11.192V10.169H10.187C10.219 10.844 10.188 17.352 10.187 17.394L12.604 17.393Z"/> }
    {...props}
  />
)

export const X = (props) => (
  <Icon
    svgContent={ <path d="M11.9674 8.82647L17.8687 2H15.2344L10.7299 7.21374L6.7339 2H1L7.6906 10.7273L1.4032 18H4.0375L8.9281 12.3444L13.2661 18H19L11.9674 8.82647ZM9.9271 11.1868L8.6896 9.57141L3.88 3.30167H5.86L9.7408 8.35546L10.9783 9.97084L16.1362 16.6983H14.1562L9.9271 11.1868Z"/> }
    {...props}
  />
)
