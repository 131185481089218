import React from 'react';
import Button from '../Button/Button';

const features = [
    {
        name: 'Basic components',
        free: true,
        premium: true,
        professional: true,
        link: '#',
        linkText: 'view all'
    },
    {
        name: 'Application UI',
        free: false,
        premium: true,
        professional: false,
        link: '#',
        linkText: 'view demo'
    },
    {
        name: 'Marketing UI pre-order',
        free: false,
        premium: true,
        professional: false,
        link: null,
        linkText: null
    },
    {
        name: 'E-commerce UI pre-order',
        free: false,
        premium: true,
        professional: false,
        link: null,
        linkText: null
    }
];

const ComparisonTable = ({ plans }) => {
  return (
    <div id="detailed-pricing" className="tw-w-full tw-overflow-x-auto">
      <div className="tw-overflow-hidden">
        <div className="tw-grid tw-grid-cols-4 tw-p-4 tw-text-sm tw-font-medium tw-text-gray-900 tw-bg-gray-100 tw-border-t tw-border-b tw-border-gray-200 tw-gap-x-16 dark:tw-bg-gray-800 dark:tw-border-gray-700 dark:tw-text-white">
          <div className="tw-flex tw-items-center">Features</div>
          <div>Free Plan</div>
          <div>Premium Plan</div>
          <div>Professional Plan</div>
        </div>
        {plans.map((feature, index) => (
          <div key={index} className="tw-grid tw-grid-cols-4 tw-px-4 tw-py-5 tw-text-sm tw-text-gray-700 tw-border-b tw-border-gray-200 tw-gap-x-16 dark:tw-border-gray-700">
            <div className="tw-text-gray-500 dark:tw-text-gray-400">
              {feature.name} {feature.link && (<a href={feature.link} className="tw-text-blue-600 hover:tw-underline">{feature.linkText}</a>)}
            </div>
            <div>
              {feature.free ? (
                <svg className="tw-w-3 tw-h-3 tw-text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5.917 5.724 10.5 15 1.5" />
                </svg>
              ) : (
                <svg className="tw-w-3 tw-h-3 tw-text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                </svg>
              )}
            </div>
            <div>
              {feature.premium ? (
                <svg className="tw-w-3 tw-h-3 tw-text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5.917 5.724 10.5 15 1.5" />
                </svg>
              ) : (
                <svg className="tw-w-3 tw-h-3 tw-text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                </svg>
              )}
            </div>
            <div>
              {feature.professional ? (
                <svg className="tw-w-3 tw-h-3 tw-text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5.917 5.724 10.5 15 1.5" />
                </svg>
              ) : (
                <svg className="tw-w-3 tw-h-3 tw-text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                </svg>
              )}
            </div>
          </div>
        ))}
        <div className="tw-grid tw-grid-cols-4 tw-px-4 tw-py-5 tw-text-sm tw-text-gray-700 tw-border-b tw-border-gray-200 tw-gap-x-16 dark:tw-border-gray-700">
          <div className="tw-text-gray-500 dark:tw-text-gray-400"></div>
          <div>
            <Button color="primary" buttonText="Get Started"/>
          </div>
          <div>
            <Button color="primary" buttonText="Get Started"/>
          </div>
          <div>
            <Button color="alternative" outline buttonText="Get Started"/>
          </div>
        </div>
      </div>
    </div>
  );
};

ComparisonTable.defaultProps = {
  plans: features,
};

export default ComparisonTable;
