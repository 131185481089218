import LogoFull from '../Brand/LogoFull';
import PropTypes from 'prop-types';

const Navbar = ({ links }) => {
  return (
    <nav className="tw-w-full lg:tw-w-4/5 tw-mx-auto lg:tw-py-6 tw-pt-0 tw-bg-transparent tw-border-gray-200 tw-dark:bg-gray-900">
      <div style={{'box-shadow': '0px 10px 15px -3px #0000001A'}} className="lg:tw-rounded-full tw-bg-white tw-gap-8 tw-py-4 tw-px-8 tw-max-w-screen-xl tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-mx-auto">
        <LogoFull width="164px"/>
        <button data-collapse-toggle="navbar-default" type="button" className="tw-inline-flex tw-items-center tw-p-2 tw-w-10 tw-h-10 tw-justify-center tw-text-sm tw-text-gray-500 tw-rounded-lg md:tw-hidden hover:tw-bg-gray-100 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-gray-200 tw-dark:text-gray-400 tw-dark:hover:tw-bg-gray-700 tw-dark:focus:tw-ring-gray-600" aria-controls="navbar-default" aria-expanded="false">
          <span className="tw-sr-only">Open main menu</span>
          <svg className="tw-w-5 tw-h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
          </svg>
        </button>
        <div className="hidden tw-w-full md:tw-block md:tw-w-auto" id="navbar-default">
          <ul className="tw-font-medium tw-flex tw-flex-col tw-p-4 md:tw-p-0 tw-mt-4 tw-border tw-border-gray-100 tw-rounded-lg tw-bg-gray-50 md:tw-flex-row md:tw-space-x-8 tw-rtl:tw-space-x-reverse md:tw-mt-0 md:tw-border-0 md:tw-bg-white tw-dark:tw-bg-gray-800 md:tw-dark:tw-bg-gray-900 tw-dark:tw-border-gray-700">
            {links.map((link, index) => (
              <li key={index}>
                {link.url ? (
                  <a 
                    href={link.url} 
                    className="tw-block tw-py-2 tw-px-3 tw-text-gray-900 tw-rounded hover:tw-bg-gray-100 md:hover:tw-bg-transparent md:tw-border-0 md:hover:tw-text-blue-700 md:tw-p-0 tw-dark:tw-text-white md:tw-dark:hover:tw-text-blue-500 tw-dark:hover:tw-bg-gray-700 tw-dark:hover:tw-text-white md:tw-dark:hover:tw-bg-transparent"
                    target={link.newTab ? "_blank" : "_self"}
                    rel={link.newTab ? "noopener noreferrer" : undefined}
                  >
                    {link.name}
                  </a>
                ) : (
                  <button onClick={link.callback} className="tw-block tw-py-2 tw-px-3 tw-text-gray-900 tw-rounded hover:tw-bg-gray-100 md:hover:tw-bg-transparent md:tw-border-0 md:hover:tw-text-blue-700 md:tw-p-0 tw-dark:tw-text-white md:tw-dark:hover:tw-text-blue-500 tw-dark:hover:tw-bg-gray-700 tw-dark:hover:tw-text-white md:tw-dark:hover:tw-bg-transparent">
                    {link.name}
                  </button>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </nav>
  );
};

Navbar.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string,
      callback: PropTypes.func,
      newTab: PropTypes.bool,
    })
  ).isRequired,
};

export default Navbar;

