import React from 'react';
import PropTypes from 'prop-types';

export default function Logo({ fillColor, width, height }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fillColor}
      viewBox="0 0 29 45"
    >
      <g id="Logo">
        <path id="Vector" d="M23.1916 33.6797C20.3135 33.6797 17.6909 32.6199 15.6896 30.8742C15.5121 30.7209 15.3346 30.5595 15.1678 30.3927C15.001 30.2259 14.8369 30.0511 14.6809 29.8763C12.9164 27.8965 11.8431 25.3008 11.8431 22.4523C11.8431 19.6037 12.9137 17.0107 14.6809 15.0336C14.8342 14.8534 14.9983 14.6839 15.1678 14.5172C15.3346 14.3504 15.5121 14.189 15.6896 14.033C17.6909 12.2873 20.3135 11.2275 23.1916 11.2275C26.3226 11.2275 28.8645 8.71783 28.8645 5.61374C28.8645 4.06438 28.2297 2.66296 27.2022 1.6435C26.172 0.629427 24.7571 0 23.1889 0C21.6208 0 20.2032 0.629427 19.1757 1.6435C18.1508 2.66296 17.5133 4.06438 17.5133 5.61374C17.5133 8.45961 16.4428 11.0553 14.6755 13.0377C14.3635 13.3928 14.0273 13.7237 13.6642 14.0357C11.6683 15.7814 9.04029 16.8412 6.16752 16.8412C4.60202 16.8412 3.18177 17.4706 2.15425 18.4847C1.12941 19.5042 0.491913 20.9056 0.491913 22.4549C0.491913 24.0043 1.12672 25.4111 2.15425 26.4252C3.17908 27.4393 4.59933 28.0687 6.16752 28.0687C9.04029 28.0687 11.6683 29.1285 13.6642 30.8742C13.8471 31.0302 14.0192 31.1943 14.186 31.3584C14.3528 31.5251 14.5168 31.6946 14.6755 31.8748C16.4401 33.8519 17.5133 36.4503 17.5133 39.2935C17.5133 40.8428 18.1481 42.2496 19.1757 43.2637C20.2005 44.2778 21.6234 44.9072 23.1889 44.9072C26.3199 44.9072 28.8619 42.3976 28.8619 39.2935C28.8619 37.7441 28.227 36.3427 27.1995 35.3232C26.1693 34.3092 24.7544 33.6797 23.1863 33.6797H23.1916Z"/>
        <path id="Vector_2" d="M22.2771 15.9321C18.6377 15.9321 15.6869 18.8506 15.6869 22.4523C15.6869 26.0541 18.6377 28.9726 22.2771 28.9726C25.9165 28.9726 28.8672 26.0541 28.8672 22.4523C28.8672 18.8506 25.9165 15.9321 22.2771 15.9321ZM21.7875 19.6764C20.4426 19.6764 19.3505 20.7577 19.3505 22.0892C19.3505 22.6514 18.8905 23.1033 18.323 23.1033C17.7554 23.1033 17.2981 22.6487 17.2981 22.0892C17.2981 19.6388 19.3129 17.6456 21.7875 17.6456C22.3551 17.6456 22.8124 18.1002 22.8124 18.6596C22.8124 19.2191 22.3524 19.6737 21.7875 19.6737V19.6764Z"/>
      </g>
    </svg>
  );
}

Logo.propTypes = {
  fillColor: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

Logo.defaultProps = {
  fillColor: '#000',
  width: '29px',
  height: '45px',
};

