import React from 'react';
import PropTypes from 'prop-types';

export default function Logotype({ fillColor, width, height }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 201 34"
      fill={fillColor}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Logotype">
        <path id="Vector" d="M45.8196 33.6152C41.0021 33.704 40.4265 30.5219 40.4265 27.6034V2.89436C40.4265 1.21588 41.1769 0.110352 42.8124 0.110352C44.4478 0.110352 45.287 1.21588 45.287 2.89436V27.5604C45.287 28.8434 45.1552 29.4621 46.4813 29.4621C47.8074 29.4191 48.3373 30.4789 48.3831 31.2751C48.3831 32.6899 47.2775 33.6179 45.8196 33.6179V33.6152Z"/>
        <path id="Vector_2" d="M19.9782 26.7185C20.0213 21.7235 26.6518 18.3208 32.4861 21.2366L33.6804 21.8122V19.4694C33.6804 16.3303 32.1337 14.8294 29.3497 14.8294C28.376 14.8294 26.6087 14.9612 24.664 16.4218C22.2323 18.189 20.2445 15.0957 22.4529 13.4172C24.7501 11.5612 27.8004 11.1631 29.4815 11.1631C35.5364 11.1631 38.5437 14.0789 38.5437 20.1365V31.3182C38.5437 32.7331 37.7044 33.6611 36.2008 33.6611C34.6972 33.6611 33.7692 32.7761 33.7692 31.3639V30.7453C32.7524 32.9563 30.4983 33.7498 27.4049 33.7498C22.7649 33.7498 19.9782 30.522 19.9782 26.7212V26.7185ZM28.4647 30.21C31.16 30.21 33.6804 28.2652 33.6804 25.0374V24.5962L32.3112 24.0206C27.5367 21.8983 24.4004 24.1524 24.4004 26.7159C24.4004 28.7494 25.904 30.2073 28.4674 30.2073L28.4647 30.21Z"/>
        <path id="Vector_3" d="M47.3071 22.5195C47.3071 15.8459 51.904 11.292 58.1795 11.292C64.4549 11.292 68.2557 15.7572 68.2557 20.8383C68.2557 22.9149 66.7978 23.4017 64.498 23.8429C61.5795 24.3728 58.4861 24.5046 52.3425 24.5933C52.9612 28.3511 55.4816 29.809 58.7955 29.809C60.3879 29.809 61.9345 29.4566 63.6587 28.4398C66.0016 27.1568 67.9464 30.1183 65.4717 31.7538C63.7045 32.948 61.0953 33.7416 58.7094 33.7416C51.5033 33.7416 47.3044 29.4109 47.3044 22.5141L47.3071 22.5195ZM63.6614 20.0878C63.6157 17.1693 61.2298 15.3133 58.1795 15.3133C54.6423 15.3133 52.6088 17.3011 52.2564 20.9271C56.2347 20.9271 60.6542 21.0158 63.6614 20.0878Z"/>
        <path id="Vector_4" d="M69.6034 31.0519V13.9471C69.6034 12.2686 70.6202 11.3837 72.2099 11.3837C73.7996 11.3837 74.5527 12.2686 74.5527 13.9471V15.1414C76.0106 12.621 78.7086 11.2949 81.8019 11.2949C86.9745 11.2949 89.4492 14.1247 89.4492 20.0908V31.0519C89.4492 32.7331 88.5642 33.6611 86.9288 33.6611C85.2933 33.6611 84.3653 32.7331 84.3653 31.0519V20.3544C84.3653 16.8172 83.085 15.3163 80.2552 15.3163C76.8956 15.3163 74.6845 17.6134 74.6845 21.1506V31.0519C74.6845 32.7331 73.9341 33.6611 72.2529 33.6611C70.5717 33.6611 69.6007 32.7331 69.6007 31.0519H69.6034Z"/>
        <path id="Vector_5" d="M90.7941 22.4765C90.7941 15.5824 94.5518 11.2948 100.297 11.2948C103.436 11.2948 106.089 12.7984 107.326 15.0956V2.67379C107.326 1.03835 108.211 0.110352 109.846 0.110352C111.482 0.110352 112.41 1.03835 112.41 2.67379V31.0518C112.41 32.733 111.482 33.661 109.889 33.661C108.297 33.661 107.369 32.733 107.369 31.0518V29.7714C106.132 32.2461 103.479 33.7497 100.297 33.7497C94.5948 33.7497 90.7941 29.4191 90.7941 22.4792V22.4765ZM101.666 29.8575C105.115 29.8575 107.412 27.3371 107.412 22.4765C107.412 17.616 105.115 15.1843 101.666 15.1843C98.2181 15.1843 95.8752 17.659 95.8752 22.4765C95.8752 27.2941 98.1723 29.8575 101.666 29.8575Z"/>
        <path id="Vector_6" d="M119.108 30.2986V31.0491C119.108 32.7303 118.357 33.6583 116.765 33.6583C115.172 33.6583 114.29 32.7303 114.29 31.0491V2.67379C114.29 1.34769 115.129 0.110352 116.765 0.110352C118.4 0.110352 119.196 1.34769 119.196 2.67379V15.0498C120.479 12.7957 123.086 11.2921 126.225 11.2921C131.927 11.2921 135.462 15.5797 135.462 22.4738C135.462 29.368 131.882 33.7444 126.225 33.7444C122.997 33.7444 120.348 32.5501 119.108 30.2959V30.2986ZM119.417 25.5268C120.079 28.3108 122.158 29.8575 124.853 29.8575C128.302 29.8575 130.599 27.3371 130.599 22.4765C130.599 17.616 128.302 15.1843 124.853 15.1843C121.405 15.1843 119.196 17.659 119.108 22.0354C119.108 23.1409 119.196 24.7764 119.417 25.5268Z"/>
        <path id="Vector_7" d="M136.269 22.4767C136.269 15.6256 140.556 11.2949 147.187 11.2949C153.817 11.2949 158.059 15.6256 158.059 22.4767C158.059 29.3277 153.729 33.7472 147.187 33.7472C140.645 33.7472 136.269 29.4165 136.269 22.4767ZM147.187 29.8576C150.635 29.8576 153.153 27.3372 153.153 22.4767C153.153 17.6161 150.633 15.1845 147.187 15.1845C143.741 15.1845 141.175 17.6591 141.175 22.4767C141.175 27.2942 143.696 29.8576 147.187 29.8576Z"/>
        <path id="Vector_8" d="M181.733 31.0518V3.02616C181.733 1.12443 182.529 0.110352 184.164 0.110352C185.8 0.110352 186.596 1.12712 186.596 3.02616V20.3542L194.553 12.3089C195.438 11.3809 196.896 10.6734 198.133 11.8677C198.972 12.707 199.061 14.1218 197.867 15.4049L192.21 21.2849L199.327 29.064C200.522 30.3013 200.963 31.6732 199.903 32.8648C198.797 34.1021 197.382 33.5722 195.882 32.1143L188.501 24.5128L186.599 26.3688V31.0545C186.599 32.7357 185.848 33.6637 184.167 33.6637C182.486 33.6637 181.736 32.7357 181.736 31.0545L181.733 31.0518Z"/>
        <path id="Vector_9" d="M169.784 11.292C163.154 11.292 158.866 15.6227 158.866 22.4737C158.866 26.0378 160.001 28.9133 162 30.8688C162.842 29.7148 163.453 28.3887 163.775 26.955C163.98 26.0459 164.017 25.1017 163.888 24.1818C163.813 23.6492 163.775 23.0816 163.775 22.4764C163.775 17.6589 166.296 15.1842 169.787 15.1842C173.279 15.1842 175.753 17.6589 175.753 22.4764C175.753 26.7318 173.825 29.193 171.033 29.739C170.53 29.8924 168.66 30.0564 168.106 30.0564C165.806 30.0564 165.013 30.6886 163.262 31.899C165.013 33.096 167.224 33.7442 169.787 33.7442C176.329 33.7442 180.66 29.4136 180.66 22.4737C180.66 15.5339 176.329 11.292 169.787 11.292H169.784Z"/>
        <g id="Group">
          <path id="Vector_10" d="M16.0967 28.2649C14.55 29.3704 13.4445 29.5453 12.1641 29.5453C9.82665 29.5453 7.79042 28.6791 6.49122 27.0598C6.4428 27.0141 6.38901 26.9711 6.34059 26.9227C4.61101 25.1931 2.50216 24.0633 0.277649 23.5361C0.775273 29.8089 5.34265 33.7441 11.723 33.7441C13.7108 33.7441 16.0537 33.2142 18.0872 31.7106C20.1638 30.1639 18.0872 26.8931 16.0994 28.2622L16.0967 28.2649Z"/>
          <path id="Vector_11" d="M6.38632 17.7772C6.61496 17.5486 6.86243 17.3442 7.11528 17.1559C8.39834 15.9992 10.171 15.4021 12.1615 15.4021C13.4445 15.4021 14.6819 15.7114 15.9622 16.6394C18.4826 18.3179 20.4704 14.8265 18.1276 13.2368C16.1828 11.8219 13.7081 11.292 11.7176 11.292C5.34535 11.292 0.777967 15.0632 0.274963 21.231C2.51024 20.6796 4.63254 19.5283 6.38632 17.7746V17.7772Z"/>
        </g>
      </g>
    </svg>
  );
}

Logotype.propTypes = {
  fillColor: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

Logotype.defaultProps = {
  fillColor: '#000',
  width: '201px',
  height: '34px',
};

