import PropTypes from 'prop-types';
import Icon from '../Icons/Icon';

const Accordion = ({ items }) => {
  return (
    <div
      id="accordion-flush"
      className=""
      data-accordion="collapse"
      data-active-classes="tw-bg-white dark:tw-bg-gray-900 tw-text-gray-900 dark:tw-text-white"
      data-inactive-classes="tw-text-gray-500 dark:tw-text-gray-400"
    >
      {items.map((item, index) => (
        <div key={index}>
          <h2 id={`accordion-flush-heading-${index + 1}`}>
            <button
              type="button"
              className="tw-flex tw-items-center tw-justify-between tw-w-full tw-p-5 tw-font-medium rtl:tw-text-right tw-text-gray-500 tw-border-b tw-border-gray-200 dark:tw-border-gray-700 dark:tw-text-gray-400 tw-gap-3"
              data-accordion-target={`#accordion-flush-body-${index + 1}`}
              aria-expanded="false"
              aria-controls={`accordion-flush-body-${index + 1}`}
            >
              <span className="tw-flex tw-flex-row tw-items-center">
                <Icon name="question-mark" width="16" height="16" className="tw-me-2" /> {item.title}
              </span>
              <svg
                data-accordion-icon
                className="tw-w-3 tw-h-3 tw-shrink-0"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 10 6"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 5 5 1 1 5"
                />
              </svg>
            </button>
          </h2>
          <div
            id={`accordion-flush-body-${index + 1}`}
            className="hidden"
            aria-labelledby={`accordion-flush-heading-${index + 1}`}
          >
            <div className="tw-py-5 tw-border tw-border-gray-200 dark:tw-border-gray-700">
              {item.content.map((paragraph, idx) => (
                <p key={idx} className="tw-mb-2 tw-text-gray-500 dark:tw-text-gray-400">
                  {paragraph}
                </p>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

Accordion.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      content: PropTypes.arrayOf(PropTypes.string).isRequired,
    })
  ).isRequired,
};

export default Accordion;

